.promotion {
  padding-top: 40px;
  padding-bottom: 180px;
  width: 100%;
}

.promotion_topnav {
  width: 100%;
  height: 40px;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
  border-bottom: 1px solid #dddddd;
  background-color: white;
}

.promotion_topnav_back {
  width: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.promotion_topnav_middle {
  width: calc(100% - 120px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.promotion_topnav_right {
  width: 60px;
}

.promotion_body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 14px;
  padding-right: 14px;
}

.promotion_body_section {
  width: 100%;
}

.promotion_body_section_title {
  font-weight: 500;
  font-size: 24px;
  margin-top: 18px;
}

.promotion_body_section_row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px;
  gap: 2%;
}

.promotion_body_section_row_leftImageBox {
  height: 70px;
  width: 40px;
  background-color: #f3f3f3;
  border-radius: 8px;
  object-fit: cover;
}

.promotion_body_section_row_leftImageBox_img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.promotion_body_section_row_leftProfileBox {
  height: 40px;
  width: 40px;
  background-color: #f3f3f3;
  border-radius: 999px;
  object-fit: cover;
}

.promotion_body_section_row_leftProfileBox_img {
  width: 100%;
  height: 100%;
  border-radius: 9999px;
  object-fit: cover;
}

.promotion_body_section_row_rightText {
}

.promotion_body_section_row_select {
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 16px;
  background-color: #f3f3f3;
  padding-left: 14px;
  font-size: 1em;
}

.promotion_body_section_row_input {
  width: 100%;
  height: 50px;
  border-radius: 16px;
  border: 1px solid #222222;
  padding-left: 14px;
  padding-right: 14px;
  font-size: 1em;
}

.promotion_body_section_row_halfrow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
}

.promotion_bottombar {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0;
  width: 100%;
  background-color: white;
  padding: 14px;
  border-top: 1px solid #dddddd;
}

.promotion_bottombar_price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.promotion_bottombar_price_left {
}

.promotion_bottombar_price_right {
}

.promotion_bottombar_legal {
  font-size: 12px;
  color: #717171;
}

.promotion_bottombar_cta {
  width: calc(100%);
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f58700;
  color: black;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 24px;
}

.promotion_bottombar_cta_notfilled {
  width: calc(100%);
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid #dddddd;
  color: black;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 24px;
}
