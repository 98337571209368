.service {
  padding-top: 40px;
  padding-bottom: 80px;
  width: 100%;
}

.service_topnav {
  width: 100%;
  height: 40px;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
  border-bottom: 1px solid #dddddd;
  background-color: white;
}

.service_topnav_back {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.service_body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 60px;
}

.service_body_topvideo {
  width: 100%;
}

.service_body_description {
  width: 100%;
  padding: 14px;
}

.service_body_description_top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.service_body_description_top_left {
  font-weight: 500;
  font-size: 20px;
}

.service_body_description_top_right {
}

.service_body_form {
  width: 100%;
  padding: 14px;
}

.service_body_form_inputbox {
  width: 100%;
  margin-bottom: 18px;
}

.service_body_form_inputbox_title {
  font-size: 16px;
}

.service_body_form_inputbox_input {
  border: 1px solid #dddddd;
  height: 40px;
  font-size: 1em;
  width: 100%;
  border-radius: 8px;
  padding-left: 14px;
}

.createService_inputBtn_img {
  width: 60px;
  height: 60px;
}

.createService_inputBtn_cancelBtn {
}

.createService_inputlabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  background-color: #f3f3f3;
  padding: 14px;
  gap: 10px;
  border-radius: 8px;
}

.service_body_form_inputbox_imginput {
}

.service_body_form_cta {
  width: calc(100%);
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f58700;
  color: white;
  border-radius: 8px;
}

.service_body_boxes {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  padding: 14px;
}

.service_body_boxes_box {
  width: 48%;
  height: 45vw;
  border: 1px solid #222222;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.service_body_buy {
  width: calc(100% - 28px);
  margin-left: 14px;
  height: 50px;
  background-color: #f58700;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: black;
  font-weight: 500;
}
