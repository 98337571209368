.deliverstory {
  padding-top: 40px;
  padding-bottom: 80px;
  width: 100%;
}

.deliverstory_topnav {
  width: 100%;
  height: 40px;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
  border-bottom: 1px solid #dddddd;
  background-color: white;
}

.deliverstory_topnav_back {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.deliverstory_body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 14px;
  padding-right: 14px;
}

.deliverstory_body_checklist {
  width: 100%;
}

.deliverstory_body_checklist_row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 80px;
}

.deliverstory_body_checklist_row_left {
  width: 60px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 14px;
}

.deliverstory_body_checklist_row_right {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.deliverstory_body_checklist_row_right_btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: calc(100%);
  background-color: #f3f3f3;
  border-radius: 12px;
  height: 50px;
}

.deliverstory_body_checklist_row_right_text {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: calc(100%);
}

.deliverstory_body_checklist_row_right_text_icon {
  width: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.deliverstory_body_checklist_row_right_text_text {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 60px);
}

.deliverstory_body_checklist_row_right_input {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: calc(100%);
  background-color: white;
  padding-left: 14px;
  border: 1px solid #dddddd;
  border-radius: 12px;
  height: 50px;
  font-size: 1em;
}

.deliverstory_body_checklist_row_right_text_img {
  width: 30px;
  height: 30px;
}

.deliverstory_body_form_cta {
  width: calc(100%);
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f58700;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 24px;
}
