.profile {
  padding-top: 80px;
  padding-bottom: 80px;
  width: 100%;
}

.profile_topnav {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
  border-bottom: 1px solid #dddddd;
  background-color: white;
}

.profile_topnav_back {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.profile_topnav_left {
  width: calc(100% - 80px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.profile_topnav_right {
  width: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.profile_topnav_right_button {
  background-color: #f58700;
  border-radius: 9999px;
  width: 45px;
  height: 45px;
  box-shadow: 0px 1px 12px 2px #00000022;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  padding-bottom: 3px;
}

.profile_body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.profile_body_bio {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  gap: 10px;
}

.profile_body_bio_left {
  width: 50px;
  height: 50px;
  border-radius: 99999px;
  background-color: grey;
}

.profile_body_bio_right {
}

.profile_body_bio_left_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 9999px;
}

.profile_body_services {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.profile_body_listelement {
  width: 100%;
}

.profile_body_listelement_top {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #f0f0f0;
}

.profile_body_listelement_top_left {
  width: 64px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 14px;
}

.profile_body_listelement_top_left_img {
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  object-fit: cover;
  border-radius: 8px;
}

.profile_body_listelement_top_right {
}

.profile_body_listelement_bottom {
}
