.content {
  padding-top: 40px;
  padding-bottom: 80px;
  width: 100%;
}

.content_topnav {
  width: 100%;
  height: 40px;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
  border-bottom: 1px solid #dddddd;
  background-color: white;
}

.content_body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 12px;
}

.content_body_list_listelement {
  width: 100%;
  border-bottom: 1px solid #dddddd;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 180px;
  border-bottom: 1px solid #dddddd;
  padding-left: 14px;
  padding-right: 14px;
}

.content_body_list_listelement_left {
  width: 25%;
  height: 150px;
}

.content_body_list_listelement_left_img {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  object-fit: cover;
}

.content_body_list_listelement_right {
  width: 75%;
}

.content_body_list_listelement_right_button {
  width: calc(100% - 48px);
  margin-left: 24px;
  background-color: #f3f3f3;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin-top: 18px;
}

.content_body_list_listelement_right_button_progress {
}
