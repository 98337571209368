.settings {
  padding-top: 40px;
  padding-bottom: 80px;
  width: 100%;
}

.settings_topnav {
  width: 100%;
  height: 40px;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
  border-bottom: 1px solid #dddddd;
  background-color: white;
}

.settings_list {
  width: 100%;
}

.settings_list_element {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #dddddd;
  padding-left: 14px;
}
